import '../styles/vendors.less';
import '../styles/main.less';

import * as $ from 'jquery';
import 'jquery-mobile/js/events/touch';
import MobileDetect from "mobile-detect";
//import 'promise-polyfill/src/polyfill';

import './pageNav';
import './tableOfContents';

if (window.location.hash) {
	// redirect to new path if necessary
	let id = window.location.hash.substr(1);
	let path = (<any>window).anchorMap[id];
	if (path && path != window.location.pathname && (<any>window).anchorRoutingMap[path] != window.location.pathname) {
		window.location.href = path + '#' + id;
	}
}

/*
const onResize = () => {
	let docElement = $(document);
	let w = docElement.outerWidth(true);
	let h = docElement.outerHeight(true);
	$('#clientSize').html("clientWidth: " + w + ", clientHeight: " + h);
};
//window.onresize = onResize;
*/

$('.pinBoard').each(function() {
	let element = $(this);
	let id = element.attr('id');
	if (id && !window.localStorage.getItem(id + '_disabled')) {
		element.removeClass('hidden');
	}
});

$('.hideInit').each(function() {
	$(this).removeClass('hideInit');
});


$(function() {

	//onResize();

	const isMobile = (new MobileDetect(window.navigator.userAgent).mobile() || 'ontouchstart' in window) && true || false;

	if (isMobile) {
		console.log('treat as mobile device');

		(<any>$.event.special).swipe.horizontalDistanceThreshold = 100;
		(<any>$.event.special).swipe.durationThreshold = 10000;

		$('#page').on("swipeleft", (event) => {
			event.preventDefault();
			handleSwipe(event, 'left');
		});

		$('#page').on("swiperight",  (event) => {
			event.preventDefault();
			handleSwipe(event, 'right');
		});
	} else {
		console.log('treat as desktop device');
	}

	if ((<any>window).hasToggles) {
		$('label.toggle').each((index, element) => {
			let label = $(element);
			let input = label.find('input').first();
			input.on('change', () => {
				if (input.prop('checked')) {
					label.addClass('checked');
				} else {
					label.removeClass('checked');
				}
			});
			if (input.prop('checked')) {
				label.addClass('checked');
			}
		});
	}

	window.addEventListener("beforeunload", function(event) {
		sessionStorage.setItem("location.href", location.href);
		sessionStorage.setItem("window.scrollY", String(window.scrollY));
	});

	window.setTimeout(() => {
		const _get = parseQueryString(window.location.search);
		// @ts-ignore
		new Highlighter(_get.q).highlight(true);
	});

	window.setTimeout(() => {
		$('img.picture,img.holyPicture').each((index, element) => {
			let image = $(element);
			image.addClass('pointer').on('click', () => {
				//alert(image.attr('src'));
				let fsImage = image.clone();
				fsImage.off('click').removeAttr('class').removeAttr("style");
				$('#fs-background')
					.empty().append(fsImage).removeClass('hidden')
					.off('click').on('click', () => {
						$('#fs-background').addClass('hidden');
					});
			});
		});
	});
});

const handleSwipe = (event: JQuery.TriggeredEvent, direction: string) => {
	console.log("You swiped " + direction);
	let swipeConfig = (<any>window).SwipeConfig;
	if (swipeConfig && swipeConfig.url && swipeConfig.url[direction]) {
		//$('div.body').addClass('swipe');
		$('#swipe-content').addClass('fadeout-' + direction);
		window.setTimeout(function () {
			navigate(swipeConfig.url[direction], 1);
		}, 350);
	}
};

const navigate = (url: string, timeout?: number) => {
	if (url !== '#') {
		window.setTimeout(function () {
			//console.log(url);
			//window.top.postMessage(JSON.stringify({cmd: 'navigate', url: url}), '*');
			window.location.href = url;
		}, timeout || 50);
	}
};

(<any>window).toggleMainMenu = () => {
	let mainMenu = $('#mainMenu');
	if (mainMenu.hasClass('open')) {
		mainMenu.removeClass('open').addClass('close');
	} else {
		mainMenu.removeClass('close').addClass('open');
	}
};

(<any>window).removePinBoardPin = (id: string, left: boolean) => {
	let pinBoard = $('#' + id);
	let className = left ? 'left-pin-removed' : 'right-pin-removed';
	pinBoard.addClass(className);
	if (pinBoard.hasClass('left-pin-removed') && pinBoard.hasClass('right-pin-removed')) {
		window.localStorage.setItem(id + '_disabled', "1");
	}
};

(<any>window).$ = $;

function Highlighter(query: string) {

	const groups: any[] = [];

	if (query) {
		// parse query
		query = query
			.replace(/'([^']*)'/g, '"$1"')
			.replace(/`([^`]*)`/g, '"$1"')
			.replace(/[+-]*""/g, '')
			.replace(/:[\w:]+/g, '')
			.replace(/\s+\-\S+/g, '')
			.replace(/\s+\+(\S+)/g, ' $1')
			.replace(/\S\*/g, '\\S*')
			.replace(/]\s*\[/g, '|')
			.replace(/[\[\]]/g, '')
			.replace(/\s+\(([^\)]+)\)/g, '$1');
		console.log(query);
		while (query.match(/([+-]*)"([^"]*)"/) || query.match(/([+-]*)(\S+)/)) {
			query = query.replace(RegExp.lastMatch, '');
			//console.log(RegExp.lastMatch);
			if (RegExp.$1 != '-' && RegExp.$2) {
				const regex = RegExp.$2
					.replace(/[=-]/g, "\\s")
					.replace(/\*/g, "\\w*");
				console.log(regex);
				groups.push({
					search: new RegExp('(\\W)(' + regex + ')(\\W)', 'gi'),
					replace: '$1<span class="highlight">$2</span>$3'
				});
				groups.push({
					search: new RegExp('^(' + regex + ')(\\W)', 'i'),
					replace: '<span class="highlight">$1</span>$2'
				});
				groups.push({
					search: new RegExp('(\\W)(' + regex + ')$', 'i'),
					replace: '$1<span class="highlight">$2</span>'
				});
				groups.push({
					search: new RegExp('^(' + regex + ')$', 'i'),
					replace: '<span class="highlight">$1</span>'
				});
			}
		}
		//console.log(groups);
	}

	this.highlight = (init: boolean): Promise<any> => {
		return new Promise((resolve, reject) => {
			if (init && !groups.length) {
				resolve();
				return;
			}
			let element = $('#highlight-area');
			let html = $(element).data('html');
			if (!html) {
				html = $(element).html();
				$(element).data('html', html);
			}
			const items = html.replace(/</g, '<><').replace(/>/g, '><>').split(/<>/);
			html = "";
			for (let i = 0; i < items.length; i++) {
				let item = items[i];
				if (!item.length) {
					continue;
				}
				if (item.charAt(0) == '<' || /^\s*$/.test(item)) {
					html += item;
					continue;
				}
				//console.log(item);
				for (let j = 0; j < groups.length; j++) {
					item = item.replace(groups[j].search, groups[j].replace);
				}
				html += item;
			}
			$(element).html(html);
			//$("input[name='q']").val(original);
			resolve();
		});
	}
}

function parseQueryString(queryString: string) {
	const result: any = {};
	const items = queryString.replace(/^\?/, '').split(/[&;]/);
	for (let i = 0; i < items.length; i++) {
		const item = items[i].split(/=/);
		if (item.length < 2) {
			item.push('');
		}
		result[decodeURIComponent(item[0].replace(/\+/g, ' '))] = decodeURIComponent(item[1].replace(/\+/g, ' '));
	}
	return result;
}

import * as $ from 'jquery';

interface IDictionary<T> {
	[details: string] : T;
}

let tableOfContents = $('#tableOfContents');
let contentsBody = $('#contentsBody');
if (tableOfContents.length && contentsBody.length) {
	tableOfContents.addClass('tableOfContents');
	let tocID = 1;
	let tocEntry: JQuery<HTMLElement> = null;
	contentsBody.find(contentsBody.data('selector') || 'h2,h3').each(function(index: number) {
		let element = $(this);
		let id = element.attr('id');
		if (!id) {
			/*
			let id = element.text()
				.trim()
				.toLowerCase()
				.replace(/ä/g, 'ae')
				.replace(/ü/g, 'ue')
				.replace(/ö/g, 'oe')
				.replace(/ß/g, 'ss')
				.replace(/\W+/g, '_')
				.replace(/_$/, '')
				.replace(/^_/, '');
			*/
			id = "toc" + (tocID++);
			element.attr('id', id);
			//console.log(id);
		}
		if (index > 0) {
			tableOfContents.append($('<div class="ruler"></div>'));
		}
		tocEntry = $('<div class="flexRow alignCenter justifyMiddle h4 darkPurple2 tocItem">');
		tocEntry.append($('<div class="pl-2 pr-4 disabled symbol left"></div>'))
		tocEntry.append($('<div class="flex-1">').html(element.html()/*.replace(/<br>/gi, ' - ')*/));
		tocEntry.append($('<div class="pl-4 pr-2 disabled symbol right"></div>'))
		tocEntry.on('click', function () {
			window.location.hash = id;
		});
		tableOfContents.append(tocEntry);
		//console.log(element.html());
	});
	contentsBody.find('.loadLazy').each(function() {
		let element = $(this);
		let src = element.data('src');
		if (src) {
			element.attr('src', src).data('src', '').attr('loading', "lazy");
		}
	});
	contentsBody.find('.virtual-page').each(function(index: number) {
		if (index > 0) {
			$(this).addClass('pbb-always');
		}
	});

	window.setTimeout(function() {
		if (sessionStorage.getItem("location.href") == location.href) {
			window.scrollTo(0, parseInt(sessionStorage.getItem("window.scrollY"), 10));
		}
	});
}
